import type { AxiosRequestConfig } from "axios";
import type {
  ContractAsAdmin,
  ContractUpdateAsAdmin,
  DocumentWithoutContent,
  Label,
  PersonalData,
  TenantPowerProject,
  Termination,
  Transfer,
} from "~/src/generated-sources/admin";
import {
  type Address,
  type BankAccount,
  type ContractPartner,
  type EmailAddress,
  type DeprecatedMeterReading,
  type PhoneNumber,
  ContractType,
  EntityType,
} from "~/src/generated-sources/core";
import { useAdminImpersonificationStore } from "~/stores/admin-impersonification";
import userMessages from "~/utils/customer-area/userMessages";
import formatDate from "~/utils/customer-area/formatDate";
import getRedeemedRecommendations from "~/utils/customer-area/getRedeemedRecommendations";
import { adminPrefix } from "~/config";
import { errorHandlerFactory, ResourceConflictProblem } from "~/utils/error-mapper";
import getCurrentTariffId from "~/utils/customer-area/getContractCurrentTariff";
import { CustomerType, TariffType } from "~/src/generated-sources/public";
import downloadBase64Pdf from "~/utils/downloadBase64Pdf";

const useAdminCustomerData = (snackbar?: any) => {
  const { $brain } = useNuxtApp();
  const adminImpStore = useAdminImpersonificationStore();

  const showSuccess = (message: string, options?: { duration: number }) => {
    snackbar?.add({
      type: "success",
      text: message,
      duration: options?.duration,
    });
  };

  const showError = (error: unknown) => {
    const errorMessage = errorHandlerFactory(error);

    const type =
      Object.keys(errorMessage)[0] === ResourceConflictProblem.emailAlreadyVerified
        ? "info"
        : "error";

    snackbar?.add({
      type,
      text: Object.values(errorMessage)[0],
    });
  };

  const showWarning = (message: string) => {
    snackbar?.add({
      type: "warning",
      text: message,
      duration: 10_000,
    });
  };

  const showInfo = (message: string) => {
    snackbar?.add({
      type: "info",
      text: message,
    });
  };

  const modifyContract = async (
    contractId: string,
    contractUpdate: ContractUpdateAsAdmin,
  ) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    await $brain.admin.contracts
      .updateContract(adminImpStore.customer.id, contractId, contractUpdate)
      .then(async (response) => {
        const originalContract = adminImpStore.findContractById(contractId);
        const originalTariffId = originalContract && getCurrentTariffId(originalContract);

        adminImpStore.updateContract(response.data);
        getCustomerById(adminImpStore.customer?.id); // required for todos

        const updatedTariffId = getCurrentTariffId(response.data);
        if (originalTariffId !== updatedTariffId) {
          showWarning(
            `Tarif wurde von ${originalTariffId} zu ${updatedTariffId} geändert.`,
          );

          const isUpdatedTariffPersistedInStore = adminImpStore.tariffs?.find(
            (t) => t.id === updatedTariffId,
          );

          if (!isUpdatedTariffPersistedInStore) {
            await adminImpStore.addTariffs([updatedTariffId]);
          }

          const advancePaymentHasChanged =
            contractUpdate.advancePayment?.value !== response.data.advancePayment.value;

          if (advancePaymentHasChanged) {
            showInfo("Abschlag wurde angepasst.");
          }
        } else {
          showSuccess(userMessages.contractUpdate);
        }
      })
      .catch(showError);
  };

  const modifyBankAccount = (
    contractId: string,
    newBankAccount: BankAccount | undefined,
  ) => {
    const currentCustomer = adminImpStore.customer;
    if (!currentCustomer) throw new Error("No customer found...");

    const oldContract = adminImpStore.contracts?.find((c) => c.id === contractId);
    if (!oldContract) throw new Error("No contract found...");

    if (!newBankAccount) {
      return $brain.admin.contracts
        .deleteContractBankAccount(currentCustomer.id, contractId)
        .then(() => {
          getContractsForCustomer(currentCustomer.id); // refresh contract (replace with getContract once implemented in backend)
          showSuccess(userMessages.bankAccount);
        })
        .catch(showError);
    } else {
      return $brain.admin.contracts
        .replaceContractBankAccount(currentCustomer.id, contractId, newBankAccount)
        .then(() => {
          getContractsForCustomer(currentCustomer.id); // refresh contract (replace with getContract once implemented in backend)
          getCustomerById(currentCustomer.id); // required for bank account todo
          showSuccess(userMessages.bankAccount);
        })
        .catch(showError);
    }
  };

  const modifyBillingAddress = async (contractId: string, newBillingAddress: Address) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const oldContract = adminImpStore.contracts?.find((c) => c.id === contractId);
    if (!oldContract) throw new Error("No contract found...");

    await $brain.admin.contracts
      .replaceContractBillingAddress(
        adminImpStore.customer.id,
        contractId,
        newBillingAddress,
      )
      .then((response) => {
        const responseAdress = response.data;
        adminImpStore.updateContract({ ...oldContract, billingAddress: responseAdress });
        showSuccess(userMessages.billingAddress);
      })
      .catch(showError);
  };

  const modifyContractPartner = async (
    contractId: string,
    newContractPartner: ContractPartner,
  ) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const oldContract = adminImpStore.contracts?.find((c) => c.id === contractId);
    if (!oldContract) throw new Error("No contract found...");

    await $brain.admin.contracts
      .replaceContractPartner(adminImpStore.customer.id, contractId, newContractPartner)
      .then((response) => {
        const responseContractPartner: ContractPartner = response.data;
        adminImpStore.updateContract({
          ...oldContract,
          contractPartner: responseContractPartner,
        });
        showSuccess(userMessages.contractPartner.change);
      })
      .catch(showError);
  };

  const removeContractPartner = async (contractId: string) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const oldContract = adminImpStore.contracts?.find((c) => c.id === contractId);
    if (!oldContract) throw new Error("No contract found...");

    await $brain.admin.contracts
      .deleteContractPartner(adminImpStore.customer?.id, contractId)
      .then(() => {
        adminImpStore.updateContract({ ...oldContract, contractPartner: undefined });
        showSuccess(userMessages.contractPartner.removal);
      })
      .catch(showError);
  };

  const modifyMeterReading = async (
    contractId: string,
    meterReading: DeprecatedMeterReading,
  ) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const oldContract = adminImpStore.contracts?.find((c) => c.id === contractId);
    if (!oldContract) throw new Error("No contract found...");

    await $brain.admin.contracts
      .postMeterReading(adminImpStore.customer?.id, contractId, meterReading)
      .then((response) => {
        adminImpStore.pushMeterReadingToContract(contractId, response.data);
        showSuccess(userMessages.meterReading);
      })
      .catch(showError);
  };

  const modifyEmailAddress = async (
    newEmailAddress: EmailAddress,
    isNewsletterChange?: boolean,
  ) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const customer = adminImpStore.customer;
    if (!customer) throw new Error("No customer found...");

    const customerMailAddressBeforeUpdate = customer.emailAddress.value;

    await $brain.admin.customers
      .replaceCustomerEmailAddress(adminImpStore.customer.id, newEmailAddress)
      .then((res) => {
        const responseMail = res.data.value;

        const wasEmailVerifiedBefore = customerMailAddressBeforeUpdate !== responseMail;

        if (wasEmailVerifiedBefore) {
          customer.emailAddress.value = responseMail;
        }

        if (isNewsletterChange) {
          showSuccess(userMessages.newsletter);
        } else if (
          wasEmailVerifiedBefore &&
          // It's test_postversand@polarstern-energie.de in DEV...
          responseMail === "postversand@polarstern-energie.de"
        ) {
          showSuccess(userMessages.emailPostalDelivery);
        } else {
          showSuccess(userMessages.email);
        }
      })
      .catch(showError);
  };

  const modifyNewsletter = (newEmailAddress: EmailAddress) =>
    modifyEmailAddress(newEmailAddress, true);

  const modifyPersonalData = async (newPersonalData: PersonalData) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const customer = adminImpStore.customer;
    if (!customer) throw new Error("No customer found...");

    await $brain.admin.customers
      .replaceCustomerPersonalData(adminImpStore.customer.id, newPersonalData)
      .then(() => {
        adminImpStore.customer = {
          ...customer,
          personalData: newPersonalData,
        };

        showSuccess(userMessages.personalData);
      })
      .catch(showError);
  };

  const modifyPhoneNumber = async (
    newPhoneNumber: PhoneNumber,
    isPhoneNotification?: boolean,
  ) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const customer = adminImpStore.customer;
    if (!customer) throw new Error("No customer found...");

    await $brain.admin.customers
      .replaceCustomerPhoneNumber(adminImpStore.customer.id, newPhoneNumber)
      .then(() => {
        adminImpStore.customer = {
          ...customer,
          phoneNumber: newPhoneNumber,
        };
        if (isPhoneNotification) {
          showSuccess(userMessages.phoneNotification);
        } else {
          showSuccess(userMessages.phoneNumber);
        }
      })
      .catch(showError);
  };

  const terminateContract = async (
    customerId: string,
    contractId: string,
    termination: Termination,
  ) => {
    return await $brain.admin.contracts
      .terminateContract(customerId, contractId, termination)
      .then((response) => {
        getContractsForCustomer(customerId);
        return response;
      });
    // Errors are handled by ContractTermination.vue
  };

  const transferContract = async (customerId: string, transferTo: Transfer) => {
    return await $brain.admin.customers
      .transferCustomerContract(customerId, transferTo)
      .then((res) => {
        showSuccess(userMessages.contractTransfer);
        getContractsForCustomer(customerId);
        return res;
      });
    // Errors are handled by ContractTransfer.vue
  };

  const moveContract = async (customerId: string, contractToMove: ContractAsAdmin) => {
    return await $brain.admin.contracts
      .addContract(customerId, contractToMove)
      .then((response) => {
        getContractsForCustomer(customerId);
        return response;
      });
    // Errors are handled by ContractMove.vue
  };

  const downloadDocument = (contractId: string, documentId: string) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    $brain.admin.documents
      .getDocument(adminImpStore.customer?.id, contractId, documentId)
      .then(async (response) => {
        const { content, documentType, contractId, createdAt } = response.data;
        const fileName = `${documentType}_${contractId}_${formatDate(createdAt)}`;

        await downloadBase64Pdf(content, fileName);
        showSuccess(userMessages.documentDownload);
      })
      .catch(showError);
  };

  const getDocumentPreviewUrl = (contractId: string, documentId: string) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    return $brain.admin.documents
      .getDocument(adminImpStore.customer?.id, contractId, documentId)
      .then((response) => {
        const base64String = response.data.content;

        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteArrays);
        const file = new Blob([byteArray], { type: "application/pdf" });

        return URL.createObjectURL(file);
      })
      .catch(() => {
        showError(userMessages.errors.document);
      });
  };

  const triggerEmailVerification = async () => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");
    await $brain.admin.tokenVerification
      .triggerAnotherEmailVerification({ customerId: adminImpStore.customer.id })
      .then(() => {
        showSuccess(userMessages.email);
      })
      .catch(showError);
  };

  /**
   * Requests the given customer from the API and updates the store.
   *
   * @param customerId The ID of the customer to request
   * @returns The requested customer.
   */
  const getCustomerById = async (customerId: string) => {
    return await $brain.admin.customers.getCustomerById(customerId).then((response) => {
      const customer = response.data;
      adminImpStore.customer = customer;
      return customer;
    });
  };

  /**
   * Requests the customers for the given search term from the API.
   *
   * @param searchTerm The search term to search for.
   * @param options
   * @returns The requested customers.
   */
  const searchCustomers = async (
    searchTerm: string,
    options?: AxiosRequestConfig<any> | undefined,
  ) => {
    return await $brain.admin.customers
      .searchCustomers(searchTerm, options)
      .then(({ data }) => data.items);
  };

  /**
   * Requests the contracts for the given customer from the API and updates the store.
   *
   * @param customerId The ID of the customer to request the contracts for.
   * @returns The requested contracts.
   */
  const getContractsForCustomer = async (customerId: string) => {
    return await $brain.admin.contracts
      .getCustomerContracts(customerId)
      .then((response) => {
        const contracts = response.data.items;
        if (typeof contracts !== "undefined") {
          adminImpStore.contracts = contracts;
        }
        return contracts;
      });
  };

  const getDocumentsForCustomer = async (contractId: string) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const invoices: DocumentWithoutContent[] = [];
    const miscDocuments: DocumentWithoutContent[] = [];

    try {
      const response = await $brain.admin.documents.getDocuments(
        adminImpStore.customer?.id,
        contractId,
      );

      if (response.data.items) {
        const responseDocuments = response.data.items;

        for (const document of responseDocuments) {
          if (document.documentType.toLowerCase().includes("rechnung")) {
            invoices.push(document);
          } else {
            miscDocuments.push(document);
          }
        }
      }
    } catch (e) {
      showError(e);
    }
    return { invoices, miscDocuments };
  };

  const getAllLabels = async () => {
    const labels = (await $brain.admin.labels.getLabels()).data.items;
    adminImpStore.labels = labels || [];
    return labels;
  };

  const replaceCustomerLabels = async (labels: Label[]) => {
    if (!adminImpStore.customer?.id) throw new Error("No customer found...");

    const customer = adminImpStore.customer;

    await $brain.admin.customers
      .replaceCustomerLabels(adminImpStore.customer?.id, {
        items: labels.map((l) => l.id),
      })
      .then(() => (adminImpStore.customer = { ...customer, labels }))
      .catch(showError);
  };

  const getRecommendations = async (customerId: string) => {
    try {
      const response =
        await $brain.admin.customers.getCustomerRecommendations(customerId);
      const recommendations = response.data.items || [];
      adminImpStore.recommendations = getRedeemedRecommendations(recommendations);
      return recommendations;
    } catch (e) {
      showError(e);
    }
  };

  const resetCustomerPassword = (customerId: string) => {
    return $brain.admin.customers
      .resetCustomerPassword(customerId)
      .then((response) => {
        const newPassword = response.data.password;
        showSuccess(
          `Das Passwort wurde erfolgreich zurückgesetzt. Das neue Passwort lautet: ${newPassword}`,
          { duration: 20000 },
        );
      })
      .catch(showError);
  };

  const deleteContract = async (contractId: string) => {
    try {
      const currentCustomer = adminImpStore.customer;
      if (!currentCustomer?.id) throw new Error("No customer found...");

      const customerContracts = await getContractsForCustomer(currentCustomer.id);
      const hasOnlyOneContract = customerContracts?.length === 1;

      await $brain.admin.contracts
        .deleteContract(currentCustomer.id, contractId)
        .then(async () => {
          if (hasOnlyOneContract) {
            showSuccess(userMessages.customerDelete);
            navigateTo(routeTo("admin", ""));
          } else {
            showSuccess(userMessages.contractDelete);
            await getContractsForCustomer(currentCustomer.id);
            navigateTo(
              adminPrefix(currentCustomer.id) + routeTo("customerAreaContracts", ""),
            );
          }
        });
    } catch (e) {
      showError(e);
    }
  };

  const deleteMeterReading = async (contractId: string, meterReadingId: string) => {
    const currentCustomer = adminImpStore.customer;
    if (!currentCustomer) throw new Error("No customer found...");

    await $brain.admin.contracts
      .deleteMeterReading(currentCustomer.id, contractId, meterReadingId)
      .then(() => {
        getContractsForCustomer(currentCustomer.id); // refresh contract (replace with getContract once implemented in backend)
        showSuccess(userMessages.meterReadingDelete);
      })
      .catch((e) => showError(e));
  };

  const tariffSwitchRequest = async (contractId: string, tariffId: string) => {
    const currentCustomer = adminImpStore.customer;
    if (!currentCustomer) throw new Error("No customer found...");

    return await $brain.admin.contracts
      .switchTariff(currentCustomer.id, contractId, { tariffId })
      .then(() => {
        return true;
      })
      .catch((e) => {
        showError(e);
        return false;
      });
  };

  const getApplicableTariffsForContract = (contract: ContractAsAdmin) => {
    const type = contract.type === ContractType.Gas ? TariffType.Gas : TariffType.Power;
    const zipCode = contract.supply.address.zipCode;
    const annualConsumption = contract.annualConsumption.value;
    const annualConsumptionLow = contract.annualConsumptionLowTariff?.value;
    const customerType =
      contract.supply.address.type === EntityType.Private
        ? CustomerType.Private
        : CustomerType.Company;
    const requestedBeginAt = contract.supply.requestedBeginAt;
    const forecastBasis = contract.forecastBasis;

    return $brain.admin.tariffs
      .getTariffs(
        type,
        zipCode,
        annualConsumption,
        undefined,
        annualConsumptionLow,
        customerType,
        requestedBeginAt,
        forecastBasis,
      )
      .then((response) => {
        return response.data.items;
      })
      .catch((e) => {
        showError(e);
        return Promise.reject(e);
      });
  };

  const createTenantPowerProject = (
    tenantPowerProject: TenantPowerProject,
  ): Promise<{ projectId: string }> => {
    return $brain.admin.tenantPower
      .createProject(tenantPowerProject)
      .then((res) => {
        return { projectId: res.data.id };
      })
      .catch((e) => {
        showError(e);
        return Promise.reject(e);
      });
  };

  return {
    getCustomerById,
    getContractsForCustomer,
    modifyContract,
    modifyBankAccount,
    modifyBillingAddress,
    modifyContractPartner,
    modifyMeterReading,
    removeContractPartner,
    modifyEmailAddress,
    modifyNewsletter,
    modifyPersonalData,
    modifyPhoneNumber,
    terminateContract,
    transferContract,
    moveContract,
    downloadDocument,
    getDocumentPreviewUrl,
    triggerEmailVerification,
    getDocumentsForCustomer,
    getAllLabels,
    replaceCustomerLabels,
    resetCustomerPassword,
    getRecommendations,
    searchCustomers,
    deleteContract,
    deleteMeterReading,
    tariffSwitchRequest,
    getApplicableTariffsForContract,
    createTenantPowerProject,
  };
};

export default useAdminCustomerData;
